window.EE = window.EE || {};

(function($, window, EE) {
	'use strict';

	EE.loginFlow = {
		pageInit: function() {
			var self = this;
			this.$captchaInput = $('#captcha_response_field');
			this.isCaptchaRequired = window.currentState.current.captchaRequired;
			var $emailInput = $('#capture_signIn_traditionalSignIn_emailAddress');

			this.submitNeedToRegisterFormOnSpoofPage();
			var isMobile = EE.Utils.MobileView.Any;

			if (!isMobile) {
				$emailInput.focus();
			}

			$(document)
				.on('eeid:toggle-content', function() {
					$('#invalidLoginNotification').hide();
				})
				.on('submit', '#userInformationForm', $.proxy(self.handleFormSubmit, self))
				.on('eeid:CAPTCHA_REFRESH', function() {
					EE.Insights.generateInsights({
						event_captcha: 'LOGIN_CONDITIONAL_CAPTCHA_DISPLAYED_REFRESH'
					});
				});

			preventHoverOnMobile();

			var $spoofLogin = $('#userInformationSpoofForm');
			$spoofLogin.bind('submit', self.formSpinner);

			function preventHoverOnMobile() {
				if (isMobile) {
					$('a').each(function() {
						$(this).addClass('touch');
					});
				}
			}
			if (this.isCaptchaRequired && this.$captchaInput.hasClass('captcha-server-error')) {
				EE.EEIDvalidation.setAsInvalid(this.$captchaInput, 'required');
				this.$captchaInput.focus();
			}
		},

		submitNeedToRegisterFormOnSpoofPage: function() {
			$('#needToRegisterButton').on('click', function() {
				$('#userInformationSpoofForm').attr('action', '/register-spoof').submit();
			});
		},
		formSpinner: function(e) {
			e.preventDefault();
			if (EE.EEIDvalidation.validateForm($('#userInformationSpoofForm'))) {
				var self = this;
				var $spoofSubmitButton = $('#spoofSubmitButton');
				EE.main.showSpinner($spoofSubmitButton, true).done(function() {
					$(self).unbind('submit', EE.loginFlow.formSpinner);
					$(self).submit();
				});
			}
		},
		handleFormSubmit: function(e) {
			e.preventDefault();

			var $loginForm = $('#userInformationForm');
			var $loginBtn = $('#eeidLoginButton');
			var isFormValid = EE.EEIDvalidation.validateForm($loginForm);

			$('#autoFillEmailPlaceHolder').remove();
			$('#autoFillPasswordPlaceHolder').remove();

			EE.main.setLastActiveDate();

			if (isFormValid) {
				$loginBtn.prop('disabled', true);
				disableFormFields();
				if (this.isCaptchaRequired) {
					// Customize captcha fields for form-submit post request.
					$('#captchaExpectedAns').val($('#captcha_question_field').val());
					$('#captchaInput').val($('#captcha_response_field').val());
					var unusedCaptchaFields = [
						$('#captcha_challenge_field'),
						$('#captcha_question_field'),
						$('#captcha_theme'),
						$('#captcha_response_field')
					];
					unusedCaptchaFields.forEach(function(item) {
						item.attr('disabled', 'disabled');
					});
				}
				EE.main.showSpinner($loginBtn, true)
					.done(function() {
						$(document).off();
						$loginForm.submit();
						$('#rememberMeCheckbox').prop('disabled', true);
					});
			} else {
				EE.main.scrollToFirstErrorField();
			}

			function disableFormFields() {
				$('#userInformationForm :input').prop('readonly', true);
				$('#capture_signIn_traditionalSignIn_emailAddress').addClass('readOnly');
				$('#capture_signIn_traditionalSignIn_password').addClass('readOnly');
				$('#showPasswordSignIn').addClass('show-hide-password--readOnly');
			}
		}
	};

	EE.loginFlow.pageInit();
})($, window, EE);
